import React, { Component, useState } from 'react';
import { useTranslation, withTranslation  } from 'react-i18next';
import { Container, Row, Col } from 'reactstrap';
import './CoveredIndustries.css';

const CoveredIndustries = (props) => {
  const { t, i18n } = useTranslation("translations");
  const coveredIndustries = ['luxury-brand', 'online-ecommerce', 'mobile-gaming', 'nft-marketplace', 'tele-communication', 'nft-gallery', 'hospitality'];

  return (
    <div className='covered-industries-container clear-bottom-6x'>
      <div className="clear-top-2x clear-bottom-2x relative-container">
        <Container className="clear-bottom-2x clear-top">
          <Row>
            <Col md={{size: 12}} className="clear-top" >
              <div className='section-table'>
                <div className='section-content'>
                  <p className='section-content-title'>{t('covered-industries.title')}</p>
                  <p>{t('covered-industries.content')}</p>
                </div>
              </div>
            </Col>
            <Col>
              <div className='covered-industry-container'>
                {
                  coveredIndustries.map((value, index) => {
                    return <div className='covered-industry-item'>
                      <div className='covered-industry-inner-container'>
                        <img className="covered-industry-image clear-bottom-half" src={'../../assets/images/' + value + '-industry.png'} style={{width : "40%" }} />
                      </div>
                      <p className="covered-industry-item-title" style={{  display: 'table', margin: '0 auto'}}>{t('covered-industries.industry.' + value + '.title')}</p>
                    </div>
                  })
                }
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default CoveredIndustries;
