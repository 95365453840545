import React, { Component, useState } from 'react';
import { useTranslation, withTranslation  } from 'react-i18next';
import { useHistory } from "react-router-dom";
import { Container, Row, Col } from 'reactstrap';
import './GetInTouch.css';

const GetInTouch = (props) => {
  const { t, i18n } = useTranslation("translations");
  const path = props.path;
  const history = useHistory();
  
  const routeChange = () =>{ 
    history.push(path);
  }
  return (
    <div className='get-in-touch-container'>
      <div className='get-in-touch-button'  onClick={routeChange}>
        <p>{t('component.get-in-touch')}</p>
        <img className='get-in-touch-icon' src={'../../assets/images/get-in-touch.png'}></img>
      </div>
    </div>
  );
}

export default GetInTouch;
