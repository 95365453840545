import React, { Component, useState } from 'react';
import { useTranslation, withTranslation  } from 'react-i18next';
import Sitemap from '../sitemap/Sitemap';
import { Container, Row, Col } from 'reactstrap';
import './Footer.css';

const Footer = (props) => {
  const { t, i18n } = useTranslation("translations");
  return (
    <footer id="my-footer" className="base-footer-bg-color">
      <Sitemap />
      {/* desktop version */}
      <div className="copy-right-container desktop-only">
        <span className='footer-text'>{t('footer.copy-right-text')}</span>
      </div>
      <div className='footer-link-container desktop-only'>
        <a className="footer-text footer-link" href="/disclaimer">{t('footer.disclaimer-tab')}</a>
        <a className="footer-text footer-link" href="/terms-of-use">{t('footer.terms-of-use-tab')}</a>
        <a className="footer-text footer-link" href="/privacy-policy" >{t('footer.privacy-policy-tab')}</a>
      </div>
      {/* mobile version */}
      <div className='mobile-footer-link-container mobile-only'>
        <Container>
          <Row>
            <Col sm={{size: 12}} className='clear-top-2x clear-bottom-2x'>
              <div className='footer-link-mobile-container'>
                <a className="footer-text footer-link" href="/disclaimer">{t('footer.disclaimer-tab')}</a>
                <a className="footer-text footer-link" href="/terms-of-use">{t('footer.terms-of-use-tab')}</a>
                <a className="footer-text footer-link" href="/privacy-policy" >{t('footer.privacy-policy-tab')}</a>
              </div>
            </Col>
            <Col sm={{size: 12}} className="clear-bottom">
              <span className='footer-text copy-right-text'>{t('footer.copy-right-text')}</span>
            </Col>
          </Row>
        </Container>
      </div>
    </footer>
  );
}

export default Footer;