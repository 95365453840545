import React, { Component, useState } from 'react';
import { useTranslation, withTranslation  } from 'react-i18next';
import { Container, Row, Col } from 'reactstrap';
import './QuickContact.css';

const QuickContact = (props) => {
  const { t, i18n } = useTranslation("translations");
  return (
    <div className='quick-contact-container'>
      <div className='quick-contact-button'>
        {t('component.quick-contact')}
        <img className='quick-contact-icon' src={'../../assets/images/contact-phone.png'}></img>
      </div>
    </div>
  );
}

export default QuickContact;
