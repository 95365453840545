import React, { Component, useState, useCallback, useEffect} from 'react';
import { useTranslation   } from 'react-i18next';
import './Consulting.css';
import { Container, Row, Col, Button, Input, Modal } from 'reactstrap';
import EmailInput from '../../components/emailInput/EmailInput';

const Consulting = (props) => {
  const { t, i18n } = useTranslation();

  const [path, setPath] = useState('/contact-us');

  return (
    <div>
      <div className="banner-bg clear-bottom" style={{backgroundImage : "url(../../assets/images/banner/consulting.jpeg)"}}>
        <a className='down-arrow-button'><img className="down-arrow-image" src={'../../assets/images/down_arrow.png'} /></a>
        <Container className="banner-slogan-container">
          <Row>
            <Col>
              <div className="clear-bottom">
                <p className="banner-title">{t('banner-slogan.consulting.title')}</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div id="scroll-target-container" className="base-bg-color-grey clear-top-2x clear-bottom-2x relative-container" >
        <Container className="clear-bottom clear-top no-boundary-container">
          <Row>
            <Col md={{ size: '5', offset: '1', order : '1' }} sm={{ size : '10', offset : '1', order: '2'}} xs={{ size : '10', offset : '1', order: '2' }} className="clear-bottom clear-top" >
              <div className='section-table max-height'>
                <div className='section-content consulting-content'>
                  <p>{t('consulting.section-1.content')}</p>
                </div>
              </div>
            </Col>
            <Col className='no-boundary-col-right' md={{ size: '6' , order : '2' }}  sm={{ size : '12', order: '1'}} xs={{ size : '12', order: '1'}}>
              <div className='section-image-container'>
                <img className='section-image section-image-left' src={'../../assets/images/consulting-section-1.jpeg'} />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="base-bg-color-grey clear-top-2x clear-bottom-2x relative-container">
        <Container className="clear-bottom clear-top no-boundary-container consulting-join-us-container">
          <Row>
            <Col md={{ size: '6'}} >
              <div className='section-image-container'>
                <img className='section-image section-image-right' src={'../../assets/images/consulting-section-2.png'} />
              </div>
            </Col>
            <Col md={{ size: '6', offset : '0'}} sm={{ size: '10' , offset : '1'}} className="join-us-context clear-bottom clear-top" >
              <div className='section-table max-height'>
                <div className='section-content consulting-join-us-content'>
                  <p className='section-content-title'>{t('join-us.title')}</p>
                  <p className='consulting-reminder'>{t('join-us.content')}</p>
                  <p className='consulting-reminder'>{t('join-us.sub-content')}</p>
                  <EmailInput path={path}/>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default Consulting;