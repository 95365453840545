import React, { Component, useState, useCallback, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import './MerchantAppTermsAndConditions.css';
import { Container, Row, Col, Button, Input, Modal } from 'reactstrap';

const MerchantAppTermsAndConditions = (props) => {
    const { t, i18n } = useTranslation();
    useEffect(() => {
        /* hide header , footer, banner */
        document.getElementById("my-header").style.display = "none";
        document.getElementById("my-footer").style.display = "none";
        document.getElementById("banner-bg").style.display = "none";
    });
    return (
        <div>
            <Container className="clear-bottom clear-top merchant-app.terms-of-use-container">
                {/* Acceptance of Terms */}
                <Row><Col><p className="section-content section-content-title"><b>{t('merchant-app.terms-of-use.acceptance-of-terms.title')}</b></p></Col></Row>
                <Row>
                    <Col>
                        <p className="section-content">
                            {t('merchant-app.terms-of-use.acceptance-of-terms.contents.content-1')}
                        </p>
                    </Col>
                </Row>
                <Row><Col><p className="section-content">{t('merchant-app.terms-of-use.acceptance-of-terms.contents.content-2')}</p></Col></Row>
            </Container>
        </div>
    );
}

export default MerchantAppTermsAndConditions;