import React, { Component, useState, useCallback, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import './MerchantAppFaq.css';
import { Container, Row, Col, Button, Input, Modal, Collapse, CardBody, Card, CardHeader } from 'reactstrap';

const MerchantAppFaq = (props) => {
    const { t, i18n } = useTranslation();
    const [cards, setCards] = useState([1, 2]);
    const [collapse, setCollapse] = useState(0);

    var requestLang = new URLSearchParams(props.location.search).get("lang");
    useEffect(() => {
        /* hide header , footer, banner */
        document.getElementById("my-header").style.display = "none";
        document.getElementById("my-footer").style.display = "none";
        document.getElementById("banner-bg").style.display = "none";

        if (requestLang != null) {
            i18n.changeLanguage(requestLang);
        }
    }, [requestLang]);
    function toggle(e) {
        let event = e.target.dataset.event;
        setCollapse(collapse === Number(event) ? 0 : Number(event));
    }
    return (
        <div>
            <div className="container">
                <h3 className="page-header">{t('merchant-app.faq.title')}</h3>
                {cards.map(index => {
                    return (
                        <Card style={{ marginBottom: '1rem' }} key={index}>
                            <CardHeader onClick={toggle} data-event={index}>{t('merchant-app.faq.question-' + index)}</CardHeader>
                            <Collapse isOpen={collapse === index}>
                                <CardBody>{t('merchant-app.faq.answer-' + index)}</CardBody>
                            </Collapse>
                        </Card>
                    )
                })}
            </div>
        </div>
    );
}

export default MerchantAppFaq;