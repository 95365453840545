import React, { Component, useState, useCallback, useEffect } from 'react';
import { useTranslation   } from 'react-i18next';
import { Container, Row, Col, Button, Input } from 'reactstrap';
import Amap from '../../components/amap/Amap';
import './ContactUs.css';
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBIcon, MDBInput, MDBCard, MDBCardBody } from 'mdbreact';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import emailjs from 'emailjs-com';
import QuickContact from '../../components/quickContact/QuickContact';
import $ from 'jquery';
import EmailInput from '../../components/emailInput/EmailInput';

const ContactUs = (props) => {
  const { t, i18n } = useTranslation();
  const [isSending, setIsSending] = useState(false);

  const submitEnquiryForm = useCallback(async() =>{
    if(isSending){
      return;
    }
    var formValues = {
      "name": $("#contact-us-enquiry-form-name").val(),
      "email": $("#contact-us-enquiry-form-email").val(),
      "companyName": $("#contact-us-enquiry-form-company").val(),
      "subject": $("#contact-us-enquiry-form-subject").val(),
      "message": $("#contact-us-enquiry-form-message").val()
    };
    sendEquiryEmail(formValues);

  });
  const sendEquiryEmail = useCallback(async(formValues) =>{
    // update state
    setIsSending(true)

    console.log(formValues);
    var template_params = {
      "name": formValues["name"],
      "email": formValues["email"],
      "companyName": formValues["companyName"],
      "subject": formValues["subject"],
      "message": formValues["message"]
   }
   
   var service_id = "zoho";
   var template_id = "contactus";
   emailjs.init('user_9pK5foMcgX6LMAlknObiF');
   emailjs.send(service_id, template_id, template_params)
          .then((response) => {
            setIsSending(false)
            alert("SUCCESS!");
          }, (err) => {
            setIsSending(false)
            alert("ERROR!");
          });

  }, [isSending]);
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('contact-us.enquiry-form.error.name.required'),
    email: Yup.string().email('contact-us.enquiry-form.error.email.invalid').required('contact-us.enquiry-form.error.email.required'),
    companyName: Yup.string().required('contact-us.enquiry-form.error.company-name.required'),
    subject: Yup.string().required('contact-us.enquiry-form.error.subject.required'),
    message: Yup.string().required('contact-us.enquiry-form.error.message.required')
  })
  const {handleSubmit, handleBlur, handleChange, values, errors, touched} = useFormik({
    initialValues: {
      name: '',
      email: '',
      companyName: '',
      subject: '',
      message: ''
    },
    validationSchema,
    onSubmit: formValues => {
      sendEquiryEmail(formValues);
    }
  });

  $(function(){
    setMapMinHeight();

    $(window).on('resize', function(){
      setMapMinHeight();
    });

    function setMapMinHeight(){
      if($(window).width() > 767)
        $(".map-container").height($(".contact-us-enquiry-form-container").height() + 'px');
    }
  });
  return (
    <div>
      <div className="banner-bg clear-bottom" style={{backgroundImage : "url(../../assets/images/banner/contact-us.jpg)"}}>
        <a className='down-arrow-button'><img className="down-arrow-image" src={'../../assets/images/down_arrow.png'} /></a>
        <Container className="banner-slogan-container">
          <Row>
            <Col>
              <div className="clear-bottom">
                <p className="banner-title">{t('banner-slogan.contact-us.title')}</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div id="scroll-target-container" className="base-bg-color-grey clear-top-2x relative-container">
        <Container className="clear-top contact-info-container">
          <Row>
            <Col md={{ size: '6' }} className="clear-bottom clear-top" >
              <div className='section-table'>
                <div className='section-content'>
                  <p className='section-content-title contact-us-section-title'>{t('contact-us.section-1.title')}</p>
                  <table id='contact-information-table' className='clear-bottom'>
                    <tr>
                      <td><img className='icon' src={'../../assets/images/contact-phone.png'} /></td>
                      <td><span>{t('contact-us.location.support-hotline.content')}</span></td>
                    </tr>
                    <tr>
                      <td><img className='icon' src={'../../assets/images/contact-email.png'} /></td>
                      <td><span>{t('contact-us.location.email.content')}</span></td>
                    </tr>
                    <tr>
                      <td><img className='icon' src={'../../assets/images/contact-address.png'} /></td>
                      <td><span>{t('contact-us.location.address.content')}</span></td>
                    </tr>
                  </table>
                  <div className='contact-us-quick-contact-container'>
                    <QuickContact/>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={{ size: '6' }} >
              <div className='section-image-container'>
                <img className='section-image section-image-right' src={'../../assets/images/contact-us-section-1.png'} />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className='billion-center-banner clear-bottom-2x' style={{ minHeight: '420px', backgroundImage : "url('../../assets/images/contact-us-billion-centre.jpeg')", backgroundSize: 'cover'}}>
       <Container>
          <Row>
            <Col md={{size : 12}}>
              <p className="billion-center-banner-title">{t('contact-us.section-2.title')}</p>
            </Col>
          </Row>
        </Container>
      </div>
      <div className='enquiry-container clear-top clear-bottom'>
        <Container>
          <Row className='desktop-only'>
            <Col md={{size : 8}}>
              <div className='section-table'>
                <div className='section-content'>
                  <p className="">{t('contact-us.enquiry-form.title')}</p>
                </div>
              </div>
            </Col>
            <Col md={{size : 3, offset: 1}}>
              <div className='section-table'>
                <div className='section-content'>
                  <img className='icon' src={'../../assets/images/location.png'} style = {{ display : 'inline-block'}} />
                   
                  <p className="" style = {{ display : 'inline-block'}}>{t('contact-us.location.title')} </p>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={{size : 8}} className='contact-us-enquiry-form-container'>
              <div className='section-table mobile-only'>
                <div className='section-content'>
                  <p className="section-content-title">{t('contact-us.enquiry-form.title')}</p>
                </div>
              </div>
              <div className='contact-us-enquiry-form-block'>
                <input id="contact-us-enquiry-form-name" className='contact-us-enquiry-form'></input>
                <input className='contact-us-enquiry-form-label' placeholder={t('contact-us.enquiry-form.label.name')} disabled></input>
              </div>
              
              <div className='contact-us-enquiry-form-block'>
                <input id="contact-us-enquiry-form-email" className='contact-us-enquiry-form'></input>
                <input className='contact-us-enquiry-form-label' placeholder={t('contact-us.enquiry-form.label.email')} disabled></input>
              </div>
              
              <div className='contact-us-enquiry-form-block'>
                <input id="contact-us-enquiry-form-company" className='contact-us-enquiry-form'></input>
                <input className='contact-us-enquiry-form-label' placeholder={t('contact-us.enquiry-form.label.company')} disabled></input>
              </div>

              <div className='contact-us-enquiry-form-block'>
                <input id="contact-us-enquiry-form-subject" className='contact-us-enquiry-form'></input>
                <input className='contact-us-enquiry-form-label' placeholder={t('contact-us.enquiry-form.label.subject')} disabled></input>
              </div>

              <div className='contact-us-enquiry-form-block'>
                <textarea id="contact-us-enquiry-form-message" className='contact-us-enquiry-form'></textarea>
                <input id="contact-us-enquiry-form-message-label" className='contact-us-enquiry-form-label' placeholder={t('contact-us.enquiry-form.label.message')} disabled></input>
                <img className='icon' id="contact-us-enquiry-form-textarea-end" src={'../../assets/images/contact-us-enquiry-form-textarea-end.png'} />
              </div>
            </Col>
            <Col className='mobile-only'>
              <div className='contact-us-enquiry-form-submit-container submit-email-container clear-top clear-bottom-2x'>
                <div className='contact-us-enquiry-form-submit-button submit-email-button' disabled={isSending} onClick={submitEnquiryForm}>
                {t('component.submit')}
                  <img className='contact-us-enquiry-form-submit-icon submit-email-icon' src={'../../assets/images/email-sent.png'}></img>
                </div>
              </div>
            </Col>
            <Col md={{size : 3, offset : 1}} className='contact-us-map-container'>
              <div className='section-table mobile-only'>
                <div className='section-content'>
                  <img className='icon' src={'../../assets/images/location.png'} style = {{ display : 'inline-block'}} />
                   
                  <p className="section-content-title" style = {{ display : 'inline-block'}}>{t('contact-us.location.title')} </p>
                </div>
              </div>
              <Amap pos={[t('contact-us.location.address.longitude'),t('contact-us.location.address.latitude')]} lang={i18n.language}/>
            </Col>  
          </Row>
          <Row className='desktop-only'>
            <Col>
              <div className='contact-us-enquiry-form-submit-container submit-email-container clear-top clear-bottom-2x'>
                <div className='contact-us-enquiry-form-submit-button submit-email-button' disabled={isSending} onClick={submitEnquiryForm} style= {{ cursor: 'pointer'}}>
                  {t('component.submit')}
                  <img className='contact-us-enquiry-form-submit-icon submit-email-icon' src={'../../assets/images/email-sent.png'}></img>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}
export default ContactUs;