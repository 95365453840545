import React, { Component, useState } from 'react';
import { useTranslation, withTranslation  } from 'react-i18next';
import { Container, Row, Col } from 'reactstrap';
import './TryItNow.css';

const TryItNow = (props) => {
  const { t, i18n } = useTranslation("translations");
  return (
    <div className='try-it-now-container'>
      <div className='try-it-now-button' onClick={()=> window.open("https://pay.weixin.qq.com/wechatpay_h5/pages/partner/partner_detail.html?name=Lab", "_blank")}>
        <p>{t('component.try-it-now')}</p>
        <img className='try-it-now-icon' src={'../../assets/images/try-it-now.png'}></img>
      </div>
    </div>
  );
}

export default TryItNow;
