import React, { Component, useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './merchantAppDisclaimer.css';
import { Container, Row, Col, Button, Input, Modal } from 'reactstrap';

const MerchantAppDisclaimer = (props) => {
    const { t, i18n } = useTranslation();
    useEffect(() => {
        /* hide header , footer, banner */
        document.getElementById("my-header").style.display = "none";
        document.getElementById("my-footer").style.display = "none";
        document.getElementById("banner-bg").style.display = "none";
    });
    return (
        <div>
            <Container className="clear-bottom clear-top disclaimer-container">
                {/* Disclaimer */}
                <Row><Col><p className="section-content section-content-title"><b>{t('merchant-app.disclaimer.title')}</b></p></Col></Row>
                <Row><Col><p className="section-content">{t('merchant-app.disclaimer.contents.content-1')}</p></Col></Row>
                <Row><Col><p className="section-content">{t('merchant-app.disclaimer.contents.content-2')}</p></Col></Row>
            </Container>
        </div>
    );
}

export default MerchantAppDisclaimer;