import React, { useState, Component } from 'react';
import { useTranslation   } from 'react-i18next';
import { Map, Marker } from 'react-amap';
import './Amap.css';
import config from '../../config.json';

const Amap = (props) => {
    const { t, i18n } = useTranslation("translations");
    const pos = props.pos;
    const zoom = 18;
    const lang = i18n.language;

    return(
        <div className="map-container">
            <Map amapkey={config.AMAP_KEY} version={config.AMAP_VERSION} center={pos} zoom={zoom} lang={lang}>
                <Marker position={pos}></Marker>
            </Map>
        </div>
    );
}
export default Amap;
