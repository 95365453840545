import React, { Component, Suspense, useState, useEffect} from 'react';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import Banner from './components/banner/Banner';
import Home from './containers/home/Home';
import AboutUs from './containers/aboutUs/AboutUs';
import Consulting from './containers/consulting/Consulting';
import SolutionIntegration from './containers/solutionIntegration/SolutionIntegration';
import OnlinePayment from './containers/onlinePayment/OnlinePayment';
import SpotPayment from './containers/spotPayment/SpotPayment';
import WechatMiniprogram from './containers/wechatMiniprogram/WechatMiniprogram';
import Portfolio from './containers/portfolio/Portfolio';
import BusinessPartnership from './containers/businessPartnership/BusinessPartnership';
import ContactUs from './containers/contactUs/ContactUs';
import PageNotFound from './containers/pageNotFound/PageNotFound';
import PrivacyPolicy from './containers/privacyPolicy/PrivacyPolicy';
import TermsOfUse from './containers/termsOfUse/TermsOfUse';
import Disclaimer from './containers/disclaimer/Disclaimer';
import { Route, BrowserRouter, Switch } from "react-router-dom";
import Supports from './containers/merchantApp/supports/Supports';
import MerchantAppPrivacyPolicy from './containers/merchantApp/merchantAppPrivacyPolicy/MerchantAppPrivacyPolicy';
import MerchantAppTermsAndConditions from './containers/merchantApp/merchantAppTermsAndConditions/MerchantAppTermsAndConditions';
import { useTranslation } from 'react-i18next';
import MerchantAppFaq from './containers/merchantApp/merchantAppFaq/MerchantAppFaq';
import MerchantAppDisClaimer from './containers/merchantApp/merchantAppDisclaimer/merchantAppDisclaimer';
import $ from 'jquery';

const Router = (props) => {
    const { t, i18n } = useTranslation();
    const [windowDimensions, setWindowDimensions] = useState(useWindowDimensions());
    const { height, width } = useWindowDimensions();
    const i18nList = ['en-US', 'zh-HK', 'zh-CN'];
    var pageContainerName = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1) != "" ? window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1) :"home" ;

    $(function(){
        $(".down-arrow-button").on('click', function(){
            $([document.documentElement, document.body]).animate({
                scrollTop: $("#scroll-target-container").offset().top
            }, 500);
        });
    })

    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return {
            width,
            height
        };
    }
      
    function useWindowDimensions() {
        const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
      
        useEffect(() => {
          function handleResize() {
            setWindowDimensions(getWindowDimensions());
          }
          window.addEventListener('resize', handleResize);
          return () => window.removeEventListener('resize', handleResize);
        }, []);
      
        return windowDimensions;
    }

    useEffect(() => {
        if(width < 768)
            $(".banner-bg").css("background-image", "url(../../assets/images/banner/" + pageContainerName  + "-mobile.jpg)");
        else
            $(".banner-bg").css("background-image", "url(../../assets/images/banner/" + pageContainerName  + ".jpg)");
    }, [width]);

    useEffect(() => {
        $(".banner-bg").css("min-height", (height) + 'px');
        $(".banner-bg").css("padding-bottom", 200 + 'px');
    }, [height]);

    return (
        <BrowserRouter>
            <div className={i18nList.some(item => i18n.language === item) ? "font-face-" + i18n.language : 'font-face-en-US'} style={{ backgroundImage: "url('../../assets/images/background.jpeg')" , backgroundSize: 'cover'}}>
                <Header />
                <div style={{ minHeight: '630px' }}>
                    {/*<Banner />*/}
                    <Switch>
                        <Route exact path="/" component={Home} />
                        <Route path="/about-us" component={AboutUs} />
                        <Route path="/our-services/consulting" component={Consulting} />
                        <Route path="/our-services/solution-integration" component={SolutionIntegration} />
                        <Route path="/our-solutions/online-payment" component={OnlinePayment} />
                        <Route path="/our-solutions/spot-payment" component={SpotPayment} />
                        <Route path="/our-solutions/wechat-mini-program" component={WechatMiniprogram} />
                        <Route path="/contact-us" component={ContactUs} />
                        <Route path="/portfolio" component={Portfolio} />
                        <Route path="/business-partnership" component={BusinessPartnership} />
                        <Route path="/privacy-policy" component={PrivacyPolicy} />
                        <Route path="/terms-of-use" component={TermsOfUse} />
                        <Route path="/disclaimer" component={Disclaimer} />
                        <Route path="/supports" component={Supports} />
                        <Route path="/merchant-app/privacy-policy" component={MerchantAppPrivacyPolicy} />
                        <Route path="/merchant-app/terms-and-conditions" component={MerchantAppTermsAndConditions} />
                        <Route path="/merchant-app/faq" component={MerchantAppFaq} />
                        <Route path="/merchant-app/disclaimer" component={MerchantAppDisClaimer} />
                        <Route component={PageNotFound} />
                    </Switch>
                </div>
                <Footer />
            </div>
        </BrowserRouter>
    );
}

export default Router;
