import React, { Component } from 'react';
import { useTranslation   } from 'react-i18next';
import { Container, Row, Col, Button, Input } from 'reactstrap';
import './BusinessPartnership.css';
import GetInTouch from '../../components/getInTouch/GetInTouch';
import CoveredIndustries from '../../components/coveredIndustries/CoveredIndustries';
import ThirdPartyPartners from '../../components/thirdPartyPartners/ThirdPartyPartners';

const BusinessPartnership = () => {
  const { t, i18n } = useTranslation();

  return (
    <div>
      <div className="banner-bg clear-bottom" style={{backgroundImage : "url(../../assets/images/banner/business-partnership.jpg)"}}>
        <a className='down-arrow-button'><img className="down-arrow-image" src={'../../assets/images/down_arrow.png'} /></a>
        <Container className="banner-slogan-container">
          <Row>
            <Col>
              <div className="clear-bottom">
                <p className="banner-title">{t('banner-slogan.business-partnership.title')}</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div id="scroll-target-container" className="base-bg-color-grey clear-top-2x clear-bottom-2x relative-container">
        <Container className="clear-bottom clear-top business-partnership-container">
          <Row>
            <Col md={{ size: '6' , offset: '0', order : '1'}} sm={{ size : '10', order : '2' , offset : '1'}}  xs={{ size : '10', order : '2' , offset : '1'}}  className="clear-bottom clear-top" >
              <div className='section-table max-height'>
                <div className='section-content'>
                  <p>{t('business-partnership.section-1.content')}</p>
                  <div className='business-partnership-get-in-touch-container'>
                    <GetInTouch/>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={{ size: '6' , offset: '0',  order : '2'}} sm={{ size : '10', order : '1' , offset : '1'}}  xs={{ size : '10', order : '1' , offset : '1'}} className="clear-bottom clear-top"  >
              <div className='section-image-container'>
                <img className='section-image section-image-right' src={'../../assets/images/business-partnership-section-1.png'} />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className='promotion-container clear-top-2x clear-bottom-2x'>
       <Container>
          <Row>
            <Col md={{size : 12}}>
              <p className="business-partnership-our-partners-title gradient-left-to-right-2c">{t('business-partnership.section-2.title')}</p>
            </Col>
          </Row>
        </Container>
        <ThirdPartyPartners/>
      </div>
      <CoveredIndustries/>
    </div>
  );

}

export default BusinessPartnership;