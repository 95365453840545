import React, { Component, useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './PrivacyPolicy.css';
import { Container, Row, Col, Button, Input, Modal } from 'reactstrap';

const PrivacyPolicy = (props) => {
  const { t, i18n } = useTranslation();
  useEffect(() => {
    if(document.getElementById("banner-bg") != null){
      /* hide banner */
      document.getElementById("banner-bg").style.display = "none";
    }
  });
  return (
    <div>
      <Container className="clear-bottom privacy-policy-container">
        {/* Privacy Policy of website */}
        <Row><Col><p className="section-content section-content-title"><b>{t('privacy-policy.title')}</b></p></Col></Row>
        <Row><Col><p className="section-content">{t('privacy-policy.opening')}</p></Col></Row>

        {/* How we collect information about you */}
        <Row><Col><p className="section-content section-content-title"><b>{t('privacy-policy.how-we-collect-information.title')}</b></p></Col></Row>
        <Row><Col><p className="section-content">{t('privacy-policy.how-we-collect-information.contents.content-1')}</p></Col></Row>

        {/* How we use cookies */}
        <Row><Col><p className="section-content section-content-title"><b>{t('privacy-policy.how-we-use-cookies.title')}</b></p></Col></Row>
        <Row><Col><p className="section-content">{t('privacy-policy.how-we-use-cookies.contents.content-1')}</p></Col></Row>
        <Row>
          <Col>
            <ul style={{color : 'white'}}>
              <li>{t('privacy-policy.how-we-use-cookies.contents.content-1-list.point-1')}</li>
              <li>{t('privacy-policy.how-we-use-cookies.contents.content-1-list.point-2')}</li>
              <li>{t('privacy-policy.how-we-use-cookies.contents.content-1-list.point-3')}</li>
              <li>{t('privacy-policy.how-we-use-cookies.contents.content-1-list.point-4')}</li>
            </ul>
          </Col>
        </Row>
        <Row><Col><p className="section-content">{t('privacy-policy.how-we-use-cookies.contents.content-2')}</p></Col></Row>
        <Row><Col><p className="section-content">{t('privacy-policy.how-we-use-cookies.contents.content-3')}</p></Col></Row>
        <Row><Col><p className="section-content">{t('privacy-policy.how-we-use-cookies.contents.content-4')}</p></Col></Row>
        <Row><Col><p className="section-content">{t('privacy-policy.how-we-use-cookies.contents.content-5')}</p></Col></Row>

        {/* How we protect and store personal information */}
        <Row><Col><p className="section-content section-content-title"><b>{t('privacy-policy.how-we-protect-and-store-personal-information.title')}</b></p></Col></Row>
        <Row><Col><p className="section-content">{t('privacy-policy.how-we-protect-and-store-personal-information.contents.content-1')}</p></Col></Row>
        <Row><Col><p className="section-content">{t('privacy-policy.how-we-protect-and-store-personal-information.contents.content-2')}</p></Col></Row>

        {/* How we use the personal information we collect */}
        <Row><Col><p className="section-content section-content-title"><b>{t('privacy-policy.how-we-use-personal-information.title')}</b></p></Col></Row>
        <Row><Col><p className="section-content">{t('privacy-policy.how-we-use-personal-information.contents.content-1')}</p></Col></Row>
        <Row>
          <Col>
            <ul style={{color : 'white'}}>
              <li>{t('privacy-policy.how-we-use-personal-information.contents.content-1-list.point-1')}</li>
              <li>{t('privacy-policy.how-we-use-personal-information.contents.content-1-list.point-2')}</li>
              <li>{t('privacy-policy.how-we-use-personal-information.contents.content-1-list.point-3')}</li>
              <li>{t('privacy-policy.how-we-use-personal-information.contents.content-1-list.point-4')}</li>
              <li>{t('privacy-policy.how-we-use-personal-information.contents.content-1-list.point-5')}</li>
              <li>{t('privacy-policy.how-we-use-personal-information.contents.content-1-list.point-6')}</li>
              <li>{t('privacy-policy.how-we-use-personal-information.contents.content-1-list.point-7')}</li>
            </ul>
          </Col>
        </Row>
        <Row><Col><p className="section-content">{t('privacy-policy.how-we-use-personal-information.contents.content-2')}</p></Col></Row>

        {/* Marketing */}
        <Row><Col><p className="section-content section-content-title"><b>{t('privacy-policy.marketing.title')}</b></p></Col></Row>
        <Row><Col><p className="section-content">{t('privacy-policy.marketing.contents.content-1')}</p></Col></Row>

        {/* How we share personal information with other parties */}
        <Row><Col><p className="section-content section-content-title"><b>{t('privacy-policy.how-we-share-personal-information.title')}</b></p></Col></Row>
        <Row><Col><p className="section-content">{t('privacy-policy.how-we-share-personal-information.contents.content-1')}</p></Col></Row>
        <Row>
          <Col>
            <ul style={{color : 'white'}}>
              <li>{t('privacy-policy.how-we-share-personal-information.contents.content-1-list.point-1')}</li>
              <li>{t('privacy-policy.how-we-share-personal-information.contents.content-1-list.point-2')}</li>
              <li>{t('privacy-policy.how-we-share-personal-information.contents.content-1-list.point-3')}</li>
              <li>{t('privacy-policy.how-we-share-personal-information.contents.content-1-list.point-4')}</li>
              <li>
                {t('privacy-policy.how-we-share-personal-information.contents.content-1-list.point-5')}
                <p>{t('privacy-policy.how-we-share-personal-information.contents.content-1-list.point-5-content-1')}</p>
                <p>{t('privacy-policy.how-we-share-personal-information.contents.content-1-list.point-5-content-2')}</p>
                <p>{t('privacy-policy.how-we-share-personal-information.contents.content-1-list.point-5-content-3')}</p>
              </li>
            </ul>
          </Col>
        </Row>
        <Row><Col><p className="section-content">{t('privacy-policy.how-we-share-personal-information.contents.content-2')}</p></Col></Row>
        <Row><Col><p className="section-content">{t('privacy-policy.how-we-share-personal-information.contents.content-3')}</p></Col></Row>

        {/* How you can contact us about privacy questions */}
        <Row><Col><p className="section-content section-content-title"><b>{t('privacy-policy.how-you-contact-us.title')}</b></p></Col></Row>
        <Row className="clear-bottom"><Col><p className="section-content">{t('privacy-policy.how-you-contact-us.contents.content-1')}</p></Col></Row>

      </Container>
    </div>
  );
}

export default PrivacyPolicy;