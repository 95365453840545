import { useTranslation, withTranslation, Trans } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import {
  Button, Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink, UncontrolledDropdown,
  DropdownToggle, DropdownMenu, DropdownItem, Dropdown
} from 'reactstrap';
import './Header.css';
import Config from '../../config.json';
import $ from 'jquery';

const Header = (props) => {
  const { t, i18n } = useTranslation('translations');
  const [isOpen, setIsOpen] = useState(false);
  const [isLangOpen, setIsLangOpen] = useState(false);
  const [isOurServiceDropdown, setIsOurServiceDropdown] = useState(false);
  const [isOurSolutionDropdown, setIsOurSolutionDropdown] = useState(false);
  const [isOurServiceDropdownDisabled, setIsOurServiceDropdownDisabled] = useState(false);
  const [isOurSolutionDropdownDisabled, setIsOurSolutionDropdownDisabled] = useState(false);
  const [lang, setLang] = useState(<div>{t('header.lang.' + i18n.language)}</div>);
  const toggle = () => setIsOpen(!isOpen);
  const langToggle = () => setIsLangOpen(!isLangOpen);
  const ourServiceDropdownToggle = (e) => {
    setIsOurServiceDropdown(!isOurServiceDropdown);
  }
  const ourSolutionDropdownToggle = (e) => {
    setIsOurSolutionDropdown(!isOurSolutionDropdown);
  }

  const changeLanguage = (lang) => {
    setLang(<div>{t('header.lang.' + lang)}</div>);
    i18n.changeLanguage(lang);
  }
  

  $(function(){
    responsiveNavExpansion();
    
    // dropdown effect
    $(".dropdown, .dropdown-menu").on('mouseover',function(){

      if($(window).width() > 767){
        // remove all effect
        removeDropdownEffect();

        // modify specific header item background color
        addDropdownEffect(this);
      }
    });

    $(".dropdown, .dropdown-menu").on('click',function(){
      // remove all effect
      removeDropdownEffect();

      // modify specific header item background color
      addDropdownEffect(this);
    });

    $('.dropdown, .dropdown-menu').on("mouseleave", function(){
      if($(window).width() > 767){
        removeDropdownEffect();
      }
    });

    function addDropdownEffect(ele){
      // modify specific header item background color
      var dropdown = $(ele).closest(".dropdown");
      $(dropdown).addClass("show");
      $(dropdown).find("a").attr("aria-expanded", "true");
      $(dropdown).find("div").attr("aria-hidden", "false");
      $(dropdown).find("div").addClass("show");
      if($(window).width() >= 768){
        $(dropdown).css("background-color", "rgb(130,150,150,0.77)");
        $(dropdown).css("width", "100%");
        $(dropdown).css("margin-left", "0%");
        $(dropdown).css("border-radius", "0px");
        $(dropdown).find(".expanded_button").attr("style","background-color : transparent !important;border-radius: 0px;");
      }else{
        if($(dropdown).hasClass("expanded-dropdown")){
          $(dropdown).css("background-color", "rgba(255, 255, 255, 0.4)");
          $(dropdown).css("width", "80%");
          $(dropdown).css("margin-left", "10%");
          $(dropdown).css("border-radius", "20px");
          $(dropdown).find(".expanded_button").attr("style","margin-bottom : 0px !important; background-color :rgba(255, 255, 255, 0.4) !important;border-radius: 20px 20px 0px 0px;");
        }
      }
    }

    function removeDropdownEffect(){
      // remove all effect
      $('.dropdown').removeClass("show");
      $('.dropdown').find("a").attr("aria-expanded", "false");
      $('.dropdown').find("div").attr("aria-hidden", "true");
      $('.dropdown').find("div").removeClass("show");
      $(".dropdown").css("background-color", "rgb(0,0,0,0)");
      $(".dropdown").find(".expanded_button").attr("style","background-color : transparent");
    }

    function responsiveNavExpansion(){
      if($(window).width() < 768){
        if($("li.dropdown.show.nav-item").length > 0){
          // remove all style
          $("li .expanded_button").attr("style", "background-color : rgba(0, 0, 0, 0) !important; border-radius: 0px;");

          // add style for expanded
          $(".expanded-dropdown .dropdown-menu").attr("style", "position: relative !important; background-color: rgba(255, 255, 255, 0.4) !important; border-radius: 0px 0px 20px 20px !important; padding-top: 0px;");
          $("li.show .expanded_button").attr("style", "margin-bottom: 0px !important; background-color : rgba(255, 255, 255, 0.4) !important; border-radius: 20px 20px 0px 0px;");
          $(".expanded-dropdown.show").attr("style", "width: 80%; margin-left: 10%; background-color : rgba(255, 255, 255, 0.4) !important; border-radius: 20px;");
        }else{
          $("li .expanded_button").attr("style", "background-color : rgba(0, 0, 0, 0) !important; border-radius: 0px;");
          $(".expanded-dropdown").attr("style", "background-color : rgba(0, 0, 0, 0) !important; border-radius: 0px;");
        }
      }else{
        //$(".expanded_button:not(.active-link)").attr("style", "background-color : rgba(0, 0, 0, 0) !important;");
        $(".expanded-dropdown .dropdown-menu").attr("style", "position: absolute !important; background-color: rgb(130,150,150,0.77) !important; border-radius: 0rem 0rem 0.5rem 0.5rem !important; padding-top: 0.5rem;");
      }
    }

    $(window).on("resize", function(){
      responsiveNavExpansion();
    });
  })
  useEffect(() => {
    if(isOpen){
      $('.navbar-toggler-icon').css("background-image", 'url("../../assets/images/nav-toggle-close.png")');
      $('.navbar-toggler').css("background-color", "rgb(130,150,150,0.77)");
    }else{
      $('.navbar-toggler-icon').css("background-image", 'url("../../assets/images/nav-toggle-open.png")');
      $('.navbar-toggler').css("background-color", "rgb(0,0,0,0)");
    }
    setIsLangOpen(false)
  }, [isOpen]);  

  useEffect(() => {
    if(isLangOpen){
      $('.my-nav-lang .dropdown').css("background-color", "rgb(130,150,150,0.77)");
    }else{
      $('.my-nav-lang .dropdown').css("background-color", "rgb(0,0,0,0)");
    }
  }, [isLangOpen]);  

  return (
    <header id="my-header">
      <Navbar fixed="top" light expand="md" className={"navbar-inverse my-nav"} >
        <div className={"border-nav"}></div>
        <NavItem className='my-nav-lang mobile-only' onClick={langToggle}>
          <UncontrolledDropdown nav inNavbar direction="down" >
            <DropdownToggle nav caret activeclassname="navbar__link--active" className="navbar__link" id="language-dropdown-toggle">
              <img src={'../../assets/images/lang.png'} width="24" height="24" />
            </DropdownToggle>
            <DropdownMenu id='language-dropdown-menu' right >
              <DropdownItem onClick={() => { changeLanguage('zh-CN'); }}>
                <NavLink activeclassname="navbar__link--active" className="navbar__link" href="#" onClick={() => { changeLanguage('zh-CN'); }}>{t('header.lang.zh-CN')}
                </NavLink>
              </DropdownItem>
              <DropdownItem onClick={() => { changeLanguage('zh-HK'); }}>
                <NavLink activeclassname="navbar__link--active" className="navbar__link" href="#" onClick={() => { changeLanguage('zh-HK'); }}>{t('header.lang.zh-HK')}
                </NavLink>
              </DropdownItem>
              <DropdownItem onClick={() => { changeLanguage('en-US'); }}>
                <NavLink activeclassname="navbar__link--active" className="navbar__link" href="#" onClick={() => { changeLanguage('en-US'); }}>{t('header.lang.en-US')}
                </NavLink>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </NavItem>
        <NavbarBrand href="/" className="my-navbar-brand"><img src={'../../assets/images/logo.png'} width="111" height="34" /></NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="my-nav-list" navbar>
            <NavItem>
              <NavLink activeclassname="navbar__link--active" className="navbar__link" href="/about-us"
                active={window.location.pathname === "/about-us"}>{t('header.about-us-tab')}</NavLink>
            </NavItem>
            <NavItem >
              <Dropdown nav inNavbar className='expanded-dropdown' active={!isOurServiceDropdownDisabled} isOpen={isOurServiceDropdown} toggle={(e) => ourServiceDropdownToggle(e)}>
                <DropdownToggle nav activeclassname="navbar__link--active" className={"expanded_button navbar__link " + (window.location.pathname === "/our-services/consulting" || window.location.pathname === "/our-services/solution-integration" ? "active-link" : "")}>
                  {t('header.our-services-tab')}
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem href="/our-services/consulting">
                    <NavLink activeclassname="navbar__link--active" className="navbar__link" href="/our-services/consulting" active={window.location.pathname === "/our-services/consulting"}>{t('header.consulting-tab')}
                      <div className={"dropdown-border"}></div>
                    </NavLink>
                  </DropdownItem>
                  <DropdownItem href="/our-services/solution-integration">
                    <NavLink activeclassname="navbar__link--active" className="navbar__link" href="/our-services/solution-integration" active={window.location.pathname === "/our-services/solution-integration"}>{t('header.solution-integration-tab')}
                      <div className={"dropdown-border"}></div>
                    </NavLink>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </NavItem>
            <NavItem >
              <Dropdown nav inNavbar className='expanded-dropdown' active={!isOurSolutionDropdownDisabled} isOpen={isOurSolutionDropdown} toggle={(e) => ourSolutionDropdownToggle(e)}>
                <DropdownToggle  nav activeclassname="navbar__link--active" className={"expanded_button navbar__link " + (window.location.pathname === "/our-solutions/online-payment" || window.location.pathname === "/our-solutions/spot-payment" || window.location.pathname === "/our-solutions/wechat-mini-program" ? "active-link" : "")}>
                  {t('header.our-solutions-tab')}
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem href="/our-solutions/online-payment">
                    <NavLink activeclassname="navbar__link--active" className="navbar__link" href="/our-solutions/online-payment" active={window.location.pathname === "/our-solutions/online-payment"}>{t('header.online-payment-tab')}
                    <div className={"dropdown-border"}></div>
                    </NavLink>
                  </DropdownItem>
                  <DropdownItem href="/our-solutions/spot-payment" >
                    <NavLink activeclassname="navbar__link--active" className="navbar__link" href="/our-solutions/spot-payment" active={window.location.pathname === "/our-solutions/spot-payment"}>{t('header.spot-payment-tab')}
                    <div className={"dropdown-border"}></div>
                    </NavLink>
                  </DropdownItem>
                  <DropdownItem href="/our-solutions/wechat-mini-program">
                    <NavLink activeclassname="navbar__link--active" className="navbar__link" href="/our-solutions/wechat-mini-program" active={window.location.pathname === "/our-solutions/wechat-mini-program"}>{t('header.wechat-mini-program-tab')}
                    <div className={"dropdown-border"}></div>
                    </NavLink>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </NavItem>
            <NavItem>
              <NavLink activeclassname="navbar__link--active" className="navbar__link" href="/portfolio" active={window.location.pathname === "/portfolio"}>{t('header.portfolio-tab')}</NavLink>
            </NavItem>
            <NavItem>
              <NavLink activeclassname="navbar__link--active" className="navbar__link" href="/business-partnership" active={window.location.pathname === "/business-partnership"}>{t('header.business-partnership-tab')}</NavLink>
            </NavItem>
            <NavItem>
              <NavLink activeclassname="navbar__link--active" className="navbar__link" href="/contact-us" active={window.location.pathname === "/contact-us"}>{t('header.contact-us-tab')}</NavLink>
            </NavItem>
            {
              /*
              <NavItem>
                <NavLink activeclassname="navbar__link--active" className="navbar__link" href={Config.LABPAY_MERCHANT_PORTAL_URL} target="_blank">{t('header.merchant-login-tab')}</NavLink>
              </NavItem>
              */
            }
            <NavItem className='my-nav-lang desktop-only'>
              <UncontrolledDropdown nav inNavbar direction="down">
                <DropdownToggle nav caret activeclassname="navbar__link--active" className="navbar__link" id="language-dropdown-toggle">
                  <img src={'../../assets/images/lang.png'} width="24" height="24" />
                </DropdownToggle>
                <DropdownMenu id='language-dropdown-menu' right>
                  <DropdownItem onClick={() => { changeLanguage('zh-CN'); }}>
                    <NavLink activeclassname="navbar__link--active" className="navbar__link" href="#" onClick={() => { changeLanguage('zh-CN'); }}>{t('header.lang.zh-CN')}
                    </NavLink>
                  </DropdownItem>
                  <DropdownItem onClick={() => { changeLanguage('zh-HK'); }}>
                    <NavLink activeclassname="navbar__link--active" className="navbar__link" href="#" onClick={() => { changeLanguage('zh-HK'); }}>{t('header.lang.zh-HK')}
                    </NavLink>
                  </DropdownItem>
                  <DropdownItem onClick={() => { changeLanguage('en-US'); }}>
                    <NavLink activeclassname="navbar__link--active" className="navbar__link" href="#" onClick={() => { changeLanguage('en-US'); }}>{t('header.lang.en-US')}
                    </NavLink>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    </header>
  );

}


export default Header;