import React, { Component } from 'react';
import { useTranslation   } from 'react-i18next';
import { Container, Row, Col, Button, Input } from 'reactstrap';
import './AboutUs.css';

const AboutUs = () => {
  const { t, i18n } = useTranslation();

  return (
    <div>
      <div className="banner-bg clear-bottom" style={{backgroundImage : "url(../../assets/images/banner/about-us.jpeg)"}}>
        <a className='down-arrow-button'><img className="down-arrow-image" src={'../../assets/images/down_arrow.png'} /></a>
        <Container className="banner-slogan-container">
          <Row>
            <Col>
              <div className="clear-bottom">
                <p className="banner-title">{t('banner-slogan.about-us.title')}</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {/* About us content section */}
      <div id="scroll-target-container" className="base-bg-color-grey clear-top-2x clear-bottom-2x relative-container" >
        <Container className="clear-bottom clear-top no-boundary-container">
          <Row>
            <Col md={{ size: '4', offset: '1', order : '1' }} sm={{ size: '10', offset: '1', order : '2'}} xs={{ size: '10', offset: '1', order : '2'}}  className="clear-bottom clear-top" >
              <div className='section-table about-us-section-table max-height'>
                <div className='section-content about-us-content'>
                  <p>{t('about-us.section-1.content')}</p>
                </div>
              </div>
            </Col>
            <Col className='no-boundary-col-right' md={{ size: '6', offset: '1', order : '2'}}  sm={{ size: '12', order : '1'}} xs={{ size: '12', order : '1'}}>
              <div className='section-image-container'>
                <img className='section-image section-image-left' src={'../../assets/images/about-us-section-1.jpeg'} />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="base-bg-color-grey clear-top-2x clear-bottom-2x relative-container">
        <Container className="clear-bottom clear-top no-boundary-container">
          <Row>
            <Col className='no-boundary-col-left' md={{ size: '6' }} sm={{ size: '12', order : '1'}} xs={{ size: '12', order: "1"}}>
              <div className='section-image-container'>
                <img className='section-image section-image-right' src={'../../assets/images/about-us-section-2.jpeg'} />
              </div>
            </Col>
            <Col md={{ size: '4', offset: '1' }} sm={{ size: '10', offset: '1', order : '2'}} xs={{  size: '10', offset: '1', order : '2'}}className="clear-bottom clear-top" >
              <div className='section-table about-us-section-table max-height'>
                <div className='section-content about-us-content'>
                  <p>{t('about-us.section-2.content')}</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="clear-top-2x clear-bottom-2x relative-container">
        <Container className="clear-bottom clear-top no-boundary-container">
          <Row>
            <Col sm={{ size: '10', offset: '1', order : '1'}} className='mobile-only'>
              <div className='section-table about-us-section-table' style={{margin: 'auto'}}>
                <div className='section-content'>
                  <p className='section-content-title about-us-title mobile-only' >{t('about-us.section-3.title')}</p>
                </div>
              </div>
            </Col>
            <Col md={{ size: '5', offset: '1' }} sm={{ size: '10', offset: '1', order : '3'}} xs={{ size: '10', offset: '1', order : '3'}} className="clear-bottom clear-top" >
              <div className='section-table about-us-section-table max-height'>
                <div className='section-content'>
                  <p className='section-content-title about-us-title desktop-only' >{t('about-us.section-3.title')}</p>
                  <p>{t('about-us.section-3.content')}</p>
                </div>
              </div>
            </Col>
            <Col md={{ size: '4', offset: '1' }} sm={{ size: '10', offset: '1', order : '2'}} xs={{ size: '10', offset: '1', order : '2'}}>
              <div className='section-image-container'>
                <img className='section-image section-image-right' src={'../../assets/images/about-us-section-3.png'} />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );

}

export default AboutUs;