import React, { Component, useState } from 'react';
import { useTranslation, withTranslation  } from 'react-i18next';
import { Container, Row, Col } from 'reactstrap';
import Carousel from "react-spring-3d-carousel";
import {v4 as uuidv4} from "uuid";
import { config } from "react-spring";
import './ThirdPartyPartners.css';
import { gsap, Cubic } from "gsap";
import $ from 'jquery';

const ThirdPartyPartners = (props) => {
  const { t, i18n } = useTranslation("translations");
  const [goToSlide, setGoToSlide] = useState(0);
  const [offsetRadius, setOffsetRadius] = useState(2);
  const [showNavigation, setShowNavigation] = useState(false);
  const [carouselConfig, setCarouselConfig] = useState(config.gentle);
  const thirdPartyPartners = ['wechat-pay', 'ali-pay', 'card-payment', 'binance-pay'];
  var slides = [];
  thirdPartyPartners.map((value, index) => {
    var obj = {};
    obj['key'] = uuidv4();
    obj['content'] = <div className='third-party-item' onClick={() => setGoToSlide(index)}>
    <p className="gradient-left-to-right-2c third-party-item-title">{t('third-party.partners.' + value + '.type')}</p>
    <div className='third-party-image-container'>
      <img className="third-party-image" src={'../../assets/images/' + value + '.png'} />
    </div>
    <p className='third-party-item-content'>{t('third-party.partners.' + value + '.content')}</p>
  </div>;
  slides.push(obj);
  });

  return (
    <div className= "third-party-outer-container">
      <div className= "third-party-container ipad-mobile-only">
        <Carousel
          slides={slides}
          goToSlide={goToSlide}
          offsetRadius={offsetRadius}
          showNavigation={showNavigation}
          animationConfig={carouselConfig}
        />
      </div>
      <div className= "third-party-container desktop-ipad-only">
      {
        thirdPartyPartners.map((value, index) => {
        return<div className='third-party-item'>
          <p className="gradient-left-to-right-2c third-party-item-title">{t('third-party.partners.' + value + '.type')}</p>
          <div className='third-party-image-container'>
            <img className="third-party-image" src={'../../assets/images/' + value + '.png'} />
          </div>
          <p className='third-party-item-content'>{t('third-party.partners.' + value + '.content')}</p>
        </div>})
      }
      </div>
    </div>

  );
}

export default ThirdPartyPartners;
