import React, { Component, useState, useCallback, useEffect} from 'react';
import { useTranslation   } from 'react-i18next';
import './Home.css';
import { Container, Row, Col, Button, Input, Modal, Glyphicon  } from 'reactstrap';
import { Link } from "react-router-dom";
import Config from '../../config.json';
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBIcon, MDBInput, MDBCard, MDBCardBody } from 'mdbreact';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import emailjs from 'emailjs-com';
import $ from 'jquery';
import EmailInput from '../../components/emailInput/EmailInput';
import ThirdPartyPartners from '../../components/thirdPartyPartners/ThirdPartyPartners';

const Home = (props) => {
  const { t, i18n } = useTranslation();
  const [isSending, setIsSending] = useState(false);
  const [path, setPath] = useState('/our-services/consulting');
  const thirdPartyPartners = ['wechat-pay', 'ali-pay', 'card-payment', 'octopus', 'binance-pay'];

  const sendNewsLetterEmail = useCallback(async(formValues) =>{
    // update state
    setIsSending(true)

    var template_params = {
      "email": formValues["email"],
   }
   
   var service_id = "zoho";
   var template_id = "service-enquiry";
   emailjs.init('user_9pK5foMcgX6LMAlknObiF');
   emailjs.send(service_id, template_id, template_params)
          .then((response) => {
            setIsSending(false)
            alert("SUCCESS!");
          }, (err) => {
            setIsSending(false)
            alert("ERROR!");
          });

  }, [isSending]);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('home.stay-connected-form.error.email.invalid').required('home.stay-connected-form.error.email.required'),
  })
  const {handleSubmit, handleBlur, handleChange, values, errors, touched} = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema,
    onSubmit: formValues => {
      sendNewsLetterEmail(formValues);
    }
  });

  $(function(){
    $(".home-banner-bg").css("background-image", "url(../../assets/images/home-logo-bg-filter.png),url(../../assets/images/banner/home.jpg)" );
  })

  return (
    <div>
      <div className="banner-bg clear-bottom home-banner-bg">
        <a className='down-arrow-button'><img className="down-arrow-image" src={'../../assets/images/down_arrow.png'} /></a>
        <Container className="banner-slogan-container">
          <Row>
            <Col>
              <div className="clear-bottom">
                <img className="clear-bottom home-lab-logo" src={'../../assets/images/logo.png'} />
                <p className="home-banner-word">{t('banner-slogan.home.efficient-innovative-future')}</p>
              </div>
              <div className="clear-bottom">
                <span className="gradient-left-to-right-3c custom-base-count">{t('banner-slogan.home.custom-base-count')}</span>
                <p className="home-banner-word">{t('banner-slogan.home.custom-base')}</p>
              </div>
              <span className='home-banner-smaller-word banner-crypto-currency-count-text'>{t('home.section-1.support')}</span>
              <span className="gradient-left-to-right-2c-2 banner-crypto-currency-count banner-crypto-currency-count-text">{t('home.section-1.40+')}</span>
              <span className='home-banner-smaller-word banner-crypto-currency-count-text'>{t('home.section-1.crypto-currencies-payment')}</span>
            </Col>
          </Row>
        </Container>
      </div>
      <div id="scroll-target-container" className='promotion-container clear-bottom-2x clear-top-2x'>
        <img className="home-left-circle" src={'../../assets/images/home-left-circle.png'}  />
        <img className="home-left-circle" src={'../../assets/images/home-left-inner-circle.png'}  />
        <img className="home-right-circle" src={'../../assets/images/home-right-circle.png'}  />
        <Container className='clear-bottom clear-top-2x home-container' style={{ fontSize: '24px' , color : 'white'}}>
          <Row>
            <Col md={{ size: 8, offset: 0}} sm={{ size : 10, offset: 1}} xs={{ size : 10, offset: 1}}>
              <img className="clear-bottom-half home-hr" src={'../../assets/images/home-hr.png'} />
              <span>{t('home.section-1.reach-out-to')}</span>
              <br/>
              <span className="gradient-left-to-right-2c custom-base-count-2">{t('banner-slogan.home.custom-base-count')}</span>
              <br/>
              <div className='world-wide-new-customers-base-container'>
                <span className='section-content home-promotion-text'>{t('home.section-1.90m+-world-wide')} </span>
                <span className="section-content gradient-left-to-right-2c home-promotion-text" style={{display : "table-cell", fontFamily: "Gotham Bold Italic", fontStyle: 'italic'}}>&nbsp;{t('home.section-1.new-customers')}&nbsp;</span>
                <span className='section-content home-promotion-text'> {t('home.section-1.base')}</span>
              </div>
            </Col>
            <Col md={{ size: 4 }}>
                <img className="home-customer-increment-img" src={'../../assets/images/home-customer-increment.png'}  />
            </Col>
          </Row>
          <Row>
            <Col md={{size: 12, offset: 0}} sm={{ size : 10, offset : 1}} xs={{ size : 10, offset: 1}}>
              <div className='section-table max-height'>
                <div className='section-content'>
                  <span className='mobile-center-wording home-promotion-text'>{t('home.section-1.support')}</span>
                  <span className="gradient-left-to-right-2c home-most-prominent-word">{t('home.section-1.40+')}</span>
                  <span className='mobile-center-wording banner-section-content home-promotion-text'>{t('home.section-1.crypto-currencies-payment')}</span>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={{ size: 6, offset: 0 }} sm={{ size : 10, offset: 1}} xs={{ size : 10, offset: 1}}>
              <img className="clear-bottom-half home-hr" src={'../../assets/images/home-hr.png'} />
              <span className='section-content mobile-center-wording home-promotion-text'>{t('home.section-1.dealing-with')}</span>
              <span className="gradient-left-to-right-2c-2 home-support-number home-prominent-word">{t('home.section-1.50+')}</span>
              <span className='section-content banner-section-content mobile-center-wording home-promotion-text'>{t('home.section-1.merchants')}</span>
            </Col>
            <Col md={{ size: 6, offset: 0 }} sm={{ size : 10, offset: 1}} xs={{ size : 10, offset: 1}}>
              <img className="clear-bottom-half home-hr" src={'../../assets/images/home-hr.png'} />
              <span className="gradient-left-to-right-2c-2 home-prominent-word home-support-number" style={{paddingTop : '20px'}}>{t('home.section-1.5+')}</span>
              <span className='section-content banner-section-content mobile-center-wording home-promotion-text experience-mobile-text'>{t('home.section-1.years-experience')}</span>
            </Col>
          </Row>
        </Container>
      </div>
      <div className='promotion-container home-digital-phone-container filter-container clear-top-2x clear-bottom-2x'>
        <Container>
          <Row>
            <Col md={{size : 6}}>
              <img className="clear-bottom-half home-digital-phone" src={'../../assets/images/home-digital-phone.png'}  />
            </Col>
            <Col md={{size : 6}}>
              <div className='section-table max-height'>
                <div className='section-content'>
                  <span>{t('home.section-2.content')}</span>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className='third-party-outer-container promotion-container clear-top-2x clear-bottom-2x'>
       <Container>
          <Row>
            <Col md={{size : 12}}>
              <p className="gradient-left-to-right-2c home-business-partnership-title" >{t('home.section-3.title')}</p>
            </Col>
          </Row>
          <Row>
            <Col md={{size : 12}}>
              <p className="base-color-orange third-party-intro-content">{t('home.section-3.content')}</p>
            </Col>
          </Row>
        </Container>
        <ThirdPartyPartners/>
      </div>
      <div className="base-bg-color-grey clear-top-2x clear-bottom-2x relative-container">
        <Container className="clear-bottom clear-top home-join-us-container">
          <Row>
            <Col className="clear-bottom clear-top" >
              <div className='section-table max-height'>
                <div className='section-content'>
                  <p className='section-content-title'>{t('join-us.title')}</p>
                  <p className='consulting-reminder'>{t('join-us.content')}</p>
                  <p className='consulting-reminder'>{t('join-us.sub-content')}</p>
                  <EmailInput path={path}/>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default Home;