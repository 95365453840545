import { useTranslation  } from 'react-i18next';
import React, { useState } from 'react';
import { Container, Row, Col, Button, Input } from 'reactstrap';
import './Sitemap.css';

const Sitemap = (props) => {
  const { t, i18n } = useTranslation("translations")

  return (
    <Container className="sitemap-container">
      <Row>
        <Col md={{ size: 4, order: 0 }}>
          <img className="footer-logo" src={'../../assets/images/logo.png'} width="191" height="58" />
        </Col>
        <Col md={{ size: 3, order: 1 }} className="clear-bottom sitemap-sub-container">
          <div className="sitemap-header base-color-orange">{t('sitemap.offer.header')}</div>
          <div className="sitemap-text"><a className="sitemap-link" href="/our-services/consulting">{t('sitemap.offer.sitelink.consulting')}</a></div>
          <div className="sitemap-text"><a className="sitemap-link" href="/our-services/solution-integration">{t('sitemap.offer.sitelink.solution-integration')}</a></div>
          <div className="sitemap-text"><a className="sitemap-link" href="/our-solutions/online-payment">{t('sitemap.offer.sitelink.online-payment')}</a></div>
          <div className="sitemap-text"><a className="sitemap-link" href="/our-solutions/spot-payment">{t('sitemap.offer.sitelink.spot-payment')}</a></div>
          <div className="sitemap-text"><a className="sitemap-link" href="/our-solutions/wechat-mini-program">{t('sitemap.offer.sitelink.wechat-mini-program')}</a></div>
        </Col>
        <Col md={{ size: 3, order: 2 }} className="clear-bottom sitemap-sub-container">
          <div className="sitemap-header base-color-orange">{t('sitemap.company.header')}</div>
          <div className="sitemap-text"><a className="sitemap-link" href="/about-us">{t('sitemap.company.sitelink.about-us')}</a></div>
          <div className="sitemap-text"><a className="sitemap-link" href="/portfolio">{t('sitemap.company.sitelink.portfolio')}</a></div>
          <div className="sitemap-text"><a className="sitemap-link" href="/business-partnership">{t('sitemap.company.sitelink.business-partnership')}</a></div>
        </Col>
        <Col md={{ size: 2, order: 3 }} className="clear-bottom sitemap-sub-container">
          <div className="sitemap-header base-color-orange">{t('sitemap.support.header')}</div>
          <div className="sitemap-text"><a className="sitemap-link" href="/contact-us">{t('sitemap.support.sitelink.contact-us')}</a></div>
        </Col>
      </Row>
    </Container>
  );
}

export default Sitemap;