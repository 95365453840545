import React, { Component, useState, useCallback, useEffect} from 'react';
import { useTranslation   } from 'react-i18next';
import './OnlinePayment.css';
import { Container, Row, Col, Button, Input, Modal } from 'reactstrap';
import GetInTouch from '../../components/getInTouch/GetInTouch';

const OnlinePayment = (props) => {
  const { t, i18n } = useTranslation();
  const [path, setPath] = useState("/contact-us");
  const benefits = ['secure', 'manage-multiple-payees', 'flexible-integration'];

  return (
    <div>
      <div className="banner-bg clear-bottom" style={{backgroundImage : "url(../../assets/images/banner/online-payment.jpg)"}}>
        <a className='down-arrow-button'><img className="down-arrow-image" src={'../../assets/images/down_arrow.png'} /></a>
        <Container className="banner-slogan-container">
          <Row>
            <Col>
              <div className="clear-bottom">
                <p className="banner-title">
                  <span className='base-color-orange'>{t('banner-slogan.online-payment.title')}</span>
                  <span> </span>
                  <span className='banner-sub-title'>{t('banner-slogan.online-payment.sub-title') } </span>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div id="scroll-target-container" className="base-bg-color-grey clear-top-2x clear-bottom-6x relative-container" >
        <Container className="clear-bottom clear-top no-boundary-container">
          <Row>
            <Col md={{ size: '4', offset: '1', order : '1' }} sm={{ size: '10', offset : '1', order : '2'}} xs={{ size: '10', offset : '1', order : '2'}}  className="clear-bottom clear-top" >
              <div className='section-table min-height-table'>
                <div className='section-content online-payment-section-content'>
                  <p>{t('online-payment.section-1.content')}</p>
                </div>
              </div>
            </Col>
            <Col md={{ size: '7', offset: '0' , order : '2'}} lg={{ size : '6', offset : '1' , order : '2'}} sm={{ size: '12', offset : '0', order : '1'}}  xs={{ size: '12', offset : '0', order : '1'}}>
              <img className='section-image section-image-full section-outscope-image' src={'../../assets/images/online-payment-section-1.jpeg'} />
            </Col>
          </Row>
        </Container>
      </div>
      <div className="clear-top-2x clear-bottom-2x relative-container">
        <Container className="clear-bottom clear-top no-boundary-container">
          <Row>
            <Col md={{ size: '5', offset: '1' }} >
              <div className='section-image-container'>
                <img className='section-image section-image-right' src={'../../assets/images/online-payment-section-2.png'} />
              </div>
              <div className='online-payment-get-in-touch-container desktop-only'>
                <GetInTouch path={path}/>
              </div>
            </Col>
            <Col md={{ size: '4', offset: '1' }} sm={{ size: '10', offset: '1' }} xs={{ size: '10', offset: '1' }} className="clear-bottom clear-top" >
              <div className='section-table max-height'>
                <div className='section-content online-payment-section-content'>
                  <p>{t('online-payment.section-2.content')}</p>
                  <p>{t('online-payment.section-2.sub-content')}</p>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className='online-payment-get-in-touch-container mobile-only'>
                <GetInTouch path={path}/>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="clear-top-2x clear-bottom-2x relative-container">
        <Container className="clear-bottom clear-top">
          <Row>
            <Col md={{size: 12}} className="clear-top" >
              <div className='section-table benefit-table'>
                <div className='section-content'>
                  <p className='section-content-title'>{t('online-payment.section-3.title')}</p>
                </div>
              </div>
            </Col>
            <Col>
              <div className='benefit-container'>
                {
                  benefits.map((value, index) => {
                    return <div className='benefit-item'>
                      <div className='section-table' style={{ height: '60%'}}>
                        <div className='section-content'>
                          <img className="clear-bottom-half clear-top" src={'../../assets/images/' + value + '.png'} style={{width : "40%" }} />
                        </div>
                      </div>
                      <p className="benefit-item-title" style={{  display: 'table', margin: '0 auto'}}>{t('online-payment.section-3.benefits.' + value + '.title')}</p>
                      <p className='benefit-item-content'>{t('online-payment.section-3.benefits.' + value + '.content')}</p>
                    </div>
                  })
                }
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default OnlinePayment;