import React, { Component, useState, useCallback, useEffect} from 'react';
import { useTranslation   } from 'react-i18next';
import './SpotPayment.css';
import { Container, Row, Col, Button, Input, Modal } from 'reactstrap';
import GetInTouch from '../../components/getInTouch/GetInTouch';
import CoveredIndustries from '../../components/coveredIndustries/CoveredIndustries';
import $ from 'jquery';

const SpotPayment = (props) => {
  const { t, i18n } = useTranslation();
  const [path, setPath] = useState("/contact-us");
  $(function(){
    dynamicSectionMinHeight();
    //dynamicOutscopeStickyContainer();

    $(window).on('resize', function(){
      dynamicSectionMinHeight();
    });
    
    function dynamicSectionMinHeight(){
      var windowWidth = $(window).width();
      var section1OriginalWidth = 2400;
      var section1OriginalHeight = 1774;
      var section2OriginalWidth = 4003;
      var section2OriginalHeight = 2958;
  
      var section1RespectiveHeight = ((windowWidth/2) * section1OriginalHeight) / section1OriginalWidth;
      $(".spot-payment-section-1-container").css("min-height", section1RespectiveHeight + 'px');
  
      var section2RespectiveHeight = ((windowWidth/2) * section2OriginalHeight) / section2OriginalWidth;
      $(".spot-payment-section-2-container").css("min-height", section2RespectiveHeight + 'px');
    }
  });

  return (
    <div>
      <div className="banner-bg clear-bottom" style={{backgroundImage : "url(../../assets/images/banner/spot-payment.jpg)"}}>
        <a className='down-arrow-button'><img className="down-arrow-image" src={'../../assets/images/down_arrow.png'} /></a>
        <Container className="banner-slogan-container">
          <Row>
            <Col>
              <div className="clear-bottom">
                <p className="banner-title">
                  <span className='base-color-light-blue'>{t('banner-slogan.spot-payment.title')}</span>
                  <span> </span>
                  <span className='banner-sub-title'>{t('banner-slogan.spot-payment.sub-title') } </span>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div id="scroll-target-container" className="base-bg-color-grey clear-top-2x clear-bottom-6x relative-container spot-payment-section-1-container" >
        <Container className="clear-bottom clear-top-2x no-boundary-container">
          <Row>
            <Col md={{ size: '4', offset: '1', order : '1'}} sm={{ size: '10', offset: '1', order : '2'}} xs={{ size: '10', offset: '1', order : '2'}} className="clear-top" >
              <div className='section-table min-height-table spot-payment-section-table'>
                <div className='section-content'>
                  <p>{t('spot-payment.section-1.content')}</p>
                </div>
              </div>
            </Col>
            <Col md={{ size: '6', offset: '1', order : '2' }} sm={{ size: '12', offset: '0', order : '1'}} xs={{ size: '12', offset: '0', order : '1'}}>
              <img className='section-image section-image-left-top-right-bottom section-outscope-top-sticky-image stick-to-right' src={'../../assets/images/spot-payment-section-1.jpeg'} />
            </Col>
            <Col md={{ size: '5', offset: '1', order : '3'}} sm={{ size: '10', offset: '1', order : '3'}} xs={{ size: '10', offset: '1', order : '3'}} className="clear-bottom spot-payment-get-in-touch-container" >
              <GetInTouch path={path}/>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="clear-top-2x clear-bottom-2x relative-container spot-payment-section-2-container" style={{display : 'none'}}>
        <Container className="clear-bottom clear-top-2x no-boundary-container">
          <Row>
            <Col md={{ size: '6'}} >
              <div className='section-image-container outscope-sticky-container'>
                <img className='section-image section-image-left-top-right-bottom section-outscope-bottom-sticky-image stick-to-left' src={'../../assets/images/spot-payment-section-2.png'} />
              </div>
            </Col>
            <Col md={{ size: '4', offset: '1' }} sm={{ size: '10', offset: '1', order : '2'}} xs={{ size: '10', offset: '1', order : '2'}} className="section-benefit clear-bottom clear-top-2x" >
              <div className='section-table'>
                <div className='section-content'>
                  <p className='section-content-title'>{t('spot-payment.section-2.title')}</p>
                  <p>{t('spot-payment.section-2.content')}</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <CoveredIndustries/>
    </div>
  );
}

export default SpotPayment;