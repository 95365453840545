import React, { Component, useState } from 'react';
import { useTranslation, withTranslation  } from 'react-i18next';
import { useHistory } from "react-router-dom";
import { Container, Row, Col } from 'reactstrap';
import './EmailInput.css';

const EmailInput = (props) => {
  const { t, i18n } = useTranslation("translations");

  const path = props.path;
  const history = useHistory();
  
  const routeChange = () =>{ 
    history.push(path);
  }

  return (
    <div className='submit-email-container'>
      <input className='submit-email-input' placeholder={t('component.email-input.placeholder')}></input>
      <div className='submit-email-button' style={{cursor: 'pointer'}} onClick={routeChange}>
        <p>{t('component.submit')}</p>
        <img className='submit-email-icon' src={'../../assets/images/email-sent.png'}></img>
      </div>
    </div>
  );
}

export default EmailInput;
