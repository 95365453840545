import React, { Component, useState, useCallback, useEffect} from 'react';
import { useTranslation   } from 'react-i18next';
import './Portfolio.css';
import { Container, Row, Col, Button, Input, Modal } from 'reactstrap';
import { gsap, Cubic } from "gsap";
import $ from 'jquery';

const Portfolio = (props) => {
  const { t, i18n } = useTranslation();
  function importAll(r) {
    return r.keys().map(r);
  }
  const images = importAll(require.context('../../assets/images/merchant-logo/', false, /\.(png|jpe?g|svg)$/));
  const coveredIndustries = ['luxury-brand', 'online-ecommerce', 'mobile-gaming', 'nft-marketplace', 'tele-communication', 'nft-gallery', 'hospitality'];


  $(function(){

    var cards = $('#card-slider .slider-item').toArray();
    var animCardLock = false;
    initCard(cards);
    initCircle();

    $(".slider-item").on('click', function(){
      if($(this).hasClass("top-slider-item")){
        if(animCardLock){
          return;
        }else{
          animCardLock = true;
          setTimeout(function(){
            animCardLock =  false;
          },1000)
        }
        var firstElem = cards.shift();
        cards.push(firstElem);
        animCard(cards);
      }
    });

    $(window).on('resize', function(){
      //initCard(cards);
      initCircle();
    });

    function animCard(array){

      var boxHeight = $($('#card-slider .slider-item')[0]).outerHeight();
      for(var index = 0 ; index < array.length ; index++){
        if(index == 0){
          gsap.fromTo(array[index], {x:0, y: 0, opacity:1}, {x:0, y:  boxHeight * 1.5, opacity:0, zIndex: 0, delay:0.03, ease: Cubic.easeInOut, duration: 0.5});
        }else if(index == 1){
          $(array).each(function(){
            $(this).removeClass("top-slider-item");
          });
          $(array[index]).css("width", '100%');
          $(array[index]).css("margin-left", '0%');
          $(array[index]).addClass("top-slider-item");
          gsap.fromTo(array[index], {x:0, y: boxHeight * 0.5, opacity:0.75, zIndex: 0}, {x:0, y: 0, opacity:1, zIndex: 3, ease: Cubic.easeInOut, duration: 0.5});
          $(".circle-tag-number").css("font-weight", 'normal');
          $(".circle-tag-number[data-tag='" + $(array[index]).attr("data-tag") + "']").css("font-weight", 'bold');
          
        }else if(index == 2){
          $(array[index]).css("width", '80%');
          $(array[index]).css("margin-left", '10%');
          gsap.fromTo(array[index], {x:0, y: boxHeight * 1, opacity:0.5, zIndex: 0}, {x:0, y: boxHeight * 0.5, opacity:0.75, zIndex: 2, ease: Cubic.easeInOut, duration: 0.5});
          
        }else if(index == 3){
          $(array[index]).css("width", '70%');
          $(array[index]).css("margin-left", '15%');
          gsap.fromTo(array[index], {x:0, y: boxHeight * 1.5, opacity:0, zIndex: 0}, {x:0, y: boxHeight * 1, opacity:0.5, zIndex: 1, ease: Cubic.easeInOut, duration: 0.5});
        }
      }
    }

    function initCard(cards){
      var lastEle = cards.pop();
      cards.unshift(lastEle);
      //init
      animCard(cards);
      animCardLock = false;
    }

    function initCircle(){
      if($(window).width() >= 768){
        //reset 
        $("#portfolio-circle-container").css('height','auto');
        $("#portfolio-inside-circle").css("left", '-95%');

        //init inner circle position top 
        var positionTop = $("#portfolio-circle-container").width() * 0.05;
        $("#portfolio-inside-circle").css("top", positionTop + "px");
        if($(window).width() >= 1024)
          $("#portfolio-circle-container").css("min-height", $(window).width() * 0.33 * 1.5);
        else
          $("#portfolio-circle-container").css("min-height", $(window).width() * 0.33 * 2);

        //adding tag number into circle
        var innerCircleRadius = $("#portfolio-circle-container").width() * 0.95;
        $(".circle-tag-number").each(function(){
          if($(this).data("tag") == '1'){
            numPositionInCircle(-60, this, true);
          }else if ($(this).data("tag") == '2'){
            numPositionInCircle(-40, this, true);
          }else if ($(this).data("tag") == '3'){
            numPositionInCircle(-20, this, true);
          }else if ($(this).data("tag") == '4'){
            numPositionInCircle(0, this, true);
          }else if ($(this).data("tag") == '5'){
            numPositionInCircle(20, this, true);
          }else if ($(this).data("tag") == '6'){
            numPositionInCircle(40, this, true);
          }else if ($(this).data("tag") == '7'){
            numPositionInCircle(60, this, true);
          }
        });
      }else{
        //reset 
        $("#portfolio-inside-circle").css("top", "-95%");
        $("#portfolio-circle-container").css("min-height", 'auto');

        //set container height for positioning
        $("#portfolio-circle-container").height($("#portfolio-circle-container").width() * 0.5);

        //init inner circle position left 
        var positionLeft = $("#portfolio-circle-container").width() * 0.05 / 2;
        $("#portfolio-inside-circle").css("left", positionLeft + "px");

        
        //adding tag number into circle
        var innerCircleRadius = $("#portfolio-circle-container").width() * 0.95;
        $(".circle-tag-number").each(function(){
          if($(this).data("tag") == '1'){
            numPositionInCircle(-60, this, false);
          }else if ($(this).data("tag") == '2'){
            numPositionInCircle(-40, this, false);
          }else if ($(this).data("tag") == '3'){
            numPositionInCircle(-20, this, false);
          }else if ($(this).data("tag") == '4'){
            numPositionInCircle(0, this, false);
          }else if ($(this).data("tag") == '5'){
            numPositionInCircle(20, this, false);
          }else if ($(this).data("tag") == '6'){
            numPositionInCircle(40, this, false);
          }else if ($(this).data("tag") == '7'){
            numPositionInCircle(60, this, false);
          }
        });
      }
    }

    function numPositionInCircle(degree, ele, isDesktop){
      if(isDesktop){
        var innerCircleRadius = $("#portfolio-circle-container").width() * 0.95;
        var centerX = -1.5 * $(ele).width();
        var centerY = innerCircleRadius - $(ele).height()/4;

        $(ele).css("left", (centerX + innerCircleRadius * Math.cos(Math.PI * degree / 180)) + "px");
        $(ele).css("top", (centerY + innerCircleRadius * Math.sin(Math.PI * degree/ 180)) + "px");
      }else{
        var innerCircleRadius = $("#portfolio-circle-container").width() * 0.95;
        var centerX = $(window).width() > 500 ? innerCircleRadius/2 : innerCircleRadius/2 - 0.1 * $(ele).width();
        var centerY = -1.5 * $(ele).height();

        $(ele).css("left", (centerX + innerCircleRadius/2 * Math.sin(Math.PI * degree / 180)) + "px");
        $(ele).css("top", (centerY + innerCircleRadius/2 * Math.cos(Math.PI * degree/ 180)) + "px");
        
      }
    }
  }


  );

  return (
    <div>
      <div className="banner-bg clear-bottom" style={{backgroundImage : "url(../../assets/images/banner/portfolio.jpg)"}}>
        <a className='down-arrow-button'><img className="down-arrow-image" src={'../../assets/images/down_arrow.png'} /></a>
        <Container className="banner-slogan-container">
          <Row>
            <Col>
              <div className="clear-bottom">
                <p className="banner-title">{t('banner-slogan.portfolio.title')}</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div id="scroll-target-container" className='portfolio-container'>
        <Container className='no-boundary-container'>
          <Row>
            <Col md={{ size: 3}}>
              <div id="portfolio-circle-container">
                <img id='portfolio-inside-circle' src={'../../assets/images/portfolio-inside-circle.png'} />
                <img id='portfolio-outside-circle' src={'../../assets/images/portfolio-outside-circle.png'} />
                <span class="circle-tag-number" data-tag='1'>01</span>
                <span class="circle-tag-number" data-tag='2'>02</span>
                <span class="circle-tag-number" data-tag='3'>03</span>
                <span class="circle-tag-number" data-tag='4'>04</span>
                <span class="circle-tag-number" data-tag='5'>05</span>
                <span class="circle-tag-number" data-tag='6'>06</span>
                <span class="circle-tag-number" data-tag='7'>07</span>
              </div>
            </Col>
            <Col md={{ size: 3, offset: 0}} sm={{size: 10, offset : 1}} xs={{size: 10, offset : 1}} >
              <div className='portfolio-content-container'>
                <div className='section-table'>
                  <div className='section-content consulting-content'>
                    <p className='section-content-title'>{t('portfolio.section-1.title')}</p>
                    <p>{t('portfolio.section-1.content')}</p>
                    <p>{t('portfolio.section-1.sub-content')}</p>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={{ size: 6}}>
              {/* slider */}
              <div className="slider-wrap">
                <div id="card-slider" className="slider">
                  {
                    coveredIndustries.map((value, index) => {
                      return <div className="slider-item" data-tag={index + 1}>
                        <div className='sector-detail'>
                          <img className="clear-bottom-2x clear-top-2x" src={'../../assets/images/' + value + '-industry.png'} style={{height : "60px" }} />
                          <p className="clear-top" style={{textAlign: 'center'}}>{t('covered-industries.industry.' + value + '.title')} </p>
                        </div>
                        <div className='sector-number'>
                          <span className='gradient-left-to-right-2c'>{'0' + (index + 1)}</span>
                        </div>
                      </div>
                    })
                  }
                  </div>
                </div>  
            </Col>
          </Row>
          <Row>
            <Col md={{ size: 12}}>
              <div className='clear-top-2x clear-bottom-2x'>
                <p className='portfolio-and-more section-content'>{t('portfolio.section-2.content')}</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default Portfolio;