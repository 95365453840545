import React, { Component, useState, useCallback, useEffect} from 'react';
import { useTranslation   } from 'react-i18next';
import './SolutionIntegration.css';
import { Container, Row, Col, Button, Input, Modal } from 'reactstrap';
import { gsap, Cubic } from "gsap";
import Carousel from "react-spring-3d-carousel";
import {v4 as uuidv4} from "uuid";
import { config } from "react-spring";
import $ from 'jquery';

const SolutionIntegration = (props) => {
  const { t, i18n } = useTranslation();
  const [goToSlide, setGoToSlide] = useState(0);
  const [offsetRadius, setOffsetRadius] = useState(2);
  const [showNavigation, setShowNavigation] = useState(false);
  const [carouselConfig, setCarouselConfig] = useState(config.gentle);
  
  const solutions = ['online-payment', 'spot-payment', 'wechat-mini-program'];
  var slides = [];
  solutions.map((value, index) => {
    var obj = {};
    obj['key'] = uuidv4();
    obj['content'] = <div className='solution-item solution-mobile-item' onClick={() => setGoToSlide(index)}>
    <div className='section-table' style={{ height: '40%'}}>
      <div className='section-content'>
        <img className="solution-item-image clear-bottom-half clear-top" src={'../../assets/images/' + value + '.png'} style={{width : "40%" }} />
      </div>
    </div>
    <p className="solution-item-title" style={{  display: 'table', margin: '0 auto'}}>
      <span className={'solution-item-' + value}><b>{t('solution-integration.section-3.solutions.' + value + '.title')}</b> </span>
      <span >{t('solution-integration.section-3.solutions.' + value + '.sub-title')}</span>
    </p>
    <p className='solution-item-content'>{t('solution-integration.section-3.solutions.' + value + '.content')}</p>
  </div>;
  slides.push(obj);
  });
  return (
    <div style={{overflow : 'hidden'}}>
      <div className="banner-bg clear-bottom" style={{backgroundImage : "url(../../assets/images/banner/solution-integration.jpg)"}}>
        <a className='down-arrow-button'><img className="down-arrow-image" src={'../../assets/images/down_arrow.png'} /></a>
        <Container className="banner-slogan-container">
          <Row>
            <Col>
              <div className="clear-bottom">
                <p className="banner-title">{t('banner-slogan.solution-integration.title')}</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div id="scroll-target-container" className="base-bg-color-grey clear-top-2x clear-bottom-6x relative-container" >
        <Container className="clear-bottom clear-top no-boundary-container">
          <Row>
            <Col md={{ size: '4', offset: '1', order : '1' }} sm={{ size: '10', offset : '1', order : '2'}} xs={{ size: '10', offset : '1', order : '2'}}  className="clear-bottom clear-top" >
              <div className='section-table min-height-table'>
                <div className='section-content solution-integration-section-content'>
                  <p>{t('solution-integration.section-1.content')}</p>
                </div>
              </div>
            </Col>
            <Col md={{ size: '7', offset: '0' , order : '2'}} lg={{ size : '6', offset : '1' , order : '2'}} sm={{ size: '12', offset : '0', order : '1'}}  xs={{ size: '12', offset : '0', order : '1'}}>
              <img className='section-image section-image-full section-outscope-image' src={'../../assets/images/solution-integration-section-1.jpg'} />
            </Col>
          </Row>
        </Container>
      </div>
      <div className="clear-top-2x clear-bottom-2x relative-container">
        <Container className="clear-bottom clear-top no-boundary-container">
          <Row>
            <Col md={{ size: '5', offset: '1' }} >
              <div className='section-image-container'>
                <img className='section-image section-image-right' src={'../../assets/images/solution-integration-section-2.png'} />
              </div>
            </Col>
            <Col md={{ size: '4', offset: '1' }} sm={{ size: '10', offset: '1'}} className="clear-bottom clear-top" >
              <div className='section-table max-height'>
                <div className='section-content solution-integration-section-content'>
                  <p>{t('solution-integration.section-2.content')}</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className='desktop-only solution-outer-container'>
        <Container className="clear-bottom no-boundary-container">
          <Row>
            <Col lg={{ size : '12', offset : '0' }} md={{ size : "12" , offset : '0'}}>
              <div className='solution-container'>
                {
                  solutions.map((value, index) => {
                    return <div className='solution-item'>
                      <div className='section-table' style={{ height: '40%'}}>
                        <div className='section-content'>
                          <img className="clear-bottom-half clear-top" src={'../../assets/images/' + value + '.png'} style={{width : "40%" }} />
                        </div>
                      </div>
                      <p className="solution-item-title" style={{  display: 'table', margin: '0 auto'}}>
                        <span className={'solution-item-' + value}><b>{t('solution-integration.section-3.solutions.' + value + '.title')}</b> </span>
                        <span >{t('solution-integration.section-3.solutions.' + value + '.sub-title')}</span>
                      </p>
                      <p className='solution-item-content' style={{textAlign: 'center', fontSize: '14px'}}>{t('solution-integration.section-3.solutions.' + value + '.content')}</p>
                    </div>
                  })
                }
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className='mobile-only solution-item-container solution-mobile-item-container container'>
        <Carousel
          slides={slides}
          goToSlide={goToSlide}
          offsetRadius={offsetRadius}
          showNavigation={showNavigation}
          animationConfig={carouselConfig}
        />
      </div>
    </div>
  );
}

export default SolutionIntegration;